import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getCache, removeCache } from "../../lib/cache";
import "./Account.css";
import { confirmAlert } from "react-confirm-alert";
import { sendData } from "../../utli/apiService";
import FullScreenLoader from "../../components/FullScreenLoader";
function Account() {
  const userInfo = JSON.parse(getCache("userInfo"));
  const logout = () => {
    removeCache("token");
    removeCache("userInfo");
    window.location = "/";
  };

  const [{ loading}, setDeleteAccStatus] = useState({
    loading: false,
    error: null,
  });

  const deleteAccount = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete? Data cannot be recovered",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const res = await sendData(`user/deleteProfile/${id}`);
            setDeleteAccStatus({ loading: true, error: null });
            if (res) {
              window.location.reload();
              logout();
            }
            setDeleteAccStatus({ loading: false, error: null });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  if (loading) return <FullScreenLoader />;

  return (
    <div className="account">
      <Container>
        <Row>
          <Col lg="6" className="mx-auto">
            <Card className=" w-100">
              <CardBody className="m-4 ">
            
                <Table bordered>
                  <tr>
                    <td>Name</td>
                    <td>{userInfo.name}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{userInfo.phone}</td>
                  </tr>
                  <tr>
                    <td>Viber</td>
                    <td>{userInfo.viber}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{userInfo.email}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td> {userInfo.address}</td>
                  </tr>
                </Table>
                <ListGroup>
                  <ListGroupItem>
                    <Link to="/orders" style={{ textDecoration: "none" }}>
                      <Button className="bg-black" block>
                        Your Orders
                      </Button>
                    </Link>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Link to={`/update/${userInfo.id}`} style={{ textDecoration: "none" }}>
                    <Button className="bg-black" block>
                        <FiEdit />
                        <span> </span>
                        Update Info
                        </Button>
                    </Link>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Link
                      to="/change-password"
                      style={{ textDecoration: "none" }}
                    >
                      <Button color="success" className="text-white" block>
                        Change Password
                      </Button>
                    </Link>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Link
                      to="/report-to-admin"
                      style={{ textDecoration: "none" }}
                    >
                      <Button color="warning" className="text-white" block>
                        Report To Admin
                      </Button>
                    </Link>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Button
                      onClick={() => deleteAccount(userInfo.id)}
                      color="danger"
                      block
                    >
                      Delete Account
                    </Button>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Button
                      onClick={logout}
                      color="info"
                      className="text-white"
                      block
                    >
                      Logout
                    </Button>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    // <Container fluid className="account">
    //   <Row  className="p-0">
    //     <Col lg="12" >
    //   <Card className="mx-auto w-100 p-4">

    //     {/* <CardTitle>
    //     </CardTitle> */}
    //     <CardBody>

    //     <Table bordered>
    //         <tr>
    //           <td>Name</td>
    //           <td>{userInfo.name}</td>
    //         </tr>
    //         <tr>
    //           <td>Phone</td>
    //           <td>{userInfo.phone}</td>
    //         </tr>
    //         <tr>
    //           <td>Email</td>
    //           <td>{userInfo.email}</td>
    //         </tr>
    //         <tr>
    //           <td>Address</td>
    //           <td>{userInfo.address}</td>
    //         </tr>
    //       </Table>
    //       <ListGroup>
    //         <ListGroupItem>
    //           <Link to="/orders" style={{textDecoration:"none"}}>
    //             {" "}
    //             <Button className="bg-black" block>
    //               Your Orders
    //             </Button>
    //           </Link>
    //         </ListGroupItem>
    //         <ListGroupItem>
    //           <Link to="/change-password"style={{textDecoration:"none"}}>
    //             <Button color="success" className="text-white" block>
    //               Change Password
    //             </Button>
    //           </Link>
    //         </ListGroupItem>
    //         <ListGroupItem>
    //           <Link to="/report-to-admin"style={{textDecoration:"none"}}>
    //             <Button color="warning" className="text-white" block>
    //               Report To Admin
    //             </Button>
    //           </Link>
    //         </ListGroupItem>
    //         <ListGroupItem>
    //           <Button
    //             onClick={() => deleteAccount(userInfo.id)}
    //             color="danger"
    //             block
    //           >
    //             Delete Account
    //           </Button>
    //         </ListGroupItem>
    //         <ListGroupItem>
    //           <Button onClick={logout} color="info" className="text-white"block>
    //             Logout
    //           </Button>
    //         </ListGroupItem>
    //       </ListGroup>
    //     </CardBody>
    //   </Card>
    //   </Col>
    //   </Row>
    // </Container>
  );
}

export default Account;
