// import { response } from "msw";
import React from "react";
// import { useParams } from "react-router-dom";
import {Container, Table } from "reactstrap";
import FullScreenLoader from "../../components/FullScreenLoader";
import useQuery from "../../hooks/useQuery";
import "./PaymentInfo.css";

function PaymentInfo() {
  const {
    response: { paymentInfoList },
    loading,
    error,
  } = useQuery("paymentinfos");

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <Container fluid className="payment">
       <h4 style={{fontWeight:"300"}}>Payment Informations</h4>
          <Table hover striped dark bordered className="mx-auto">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Number</th>
                <th>Bank</th>
              </tr>
            </thead>
            <tbody>
              {paymentInfoList.data.map((element, index) => (
                <tr className="pb-3 pt-3">
                  <td>{index + 1}</td>
                  <td>{element.name}</td>
                  <td>{element.number}</td>
                  <td>
                    <img
                      src={element.image}
                      alt="bank_logo"
                      className="bank_logo"
                      
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
      {/* <Card className="p-5" style={{ width: "80%" }}>
        <CardTitle>
         
        </CardTitle>
      </Card> */}
    </Container>
  );
}

export default PaymentInfo;
