import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// import classnames from "classnames";
import { sendData } from "./../../utli/apiService";
// import { storeCache } from "./../../lib/cache";

import {
  Button,
  FormGroup,
  Form,
  InputGroup,
  Row,
  Col,
  Container,
  CardBody,
  Card,
} from "reactstrap";

import "./Register.css";
function RegisterPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [{ loading }, setLoadingStatus] = useState({
    loading: false,
    error: null,
  });

  const submitData = async (values) => {
    const response = await sendData("user/io-register", values);
    setLoadingStatus({ loading: true, error: null });
    if (response) {
      window.location = "/login";
    }
    setLoadingStatus({ loading: false, error: null });
  };

  return (
    <>
      <Container fluid className="register">
        <Row>
          <Col className="mx-auto">
            <Card>
              <CardBody>
                <div className="title text-center">
                  <h4>Register</h4>
                </div>

                <Form
                  className="submit-form"
                  onSubmit={handleSubmit(submitData)}
                >
                  <Row className="mb-2">
                    <Col md="6">
                      <FormGroup>
                        {/* <label>Name</label> */}
                        <InputGroup className="input-group-merge input-group-alternative">
                          <input
                            className="form-control pl-2"
                            placeholder=" Enter Username"
                            type="text"
                            {...register("name", {
                              required: "Name is required",
                            })}
                          />
                        </InputGroup>
                        {errors.name && (
                          <span className="text-danger">
                            {errors?.name?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        {/* <label>Phone</label> */}
                        <InputGroup className="input-group-merge input-group-alternative">
                          <input
                            className="form-control pl-2"
                            placeholder="Enter Phone"
                            type="number"
                            {...register("phone", {
                              required: "Phone is required",
                            })}
                          />
                        </InputGroup>
                        {errors.phone && (
                          <span className="text-danger">
                            {errors?.phone?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="submit-form mb-4">
                    {/* <label htmlFor="emailInput">Email</label> */}
                    <InputGroup className="input-group-merge input-group-alternative">
                          <input
                            className="form-control pl-2"
                            placeholder="Enter Email"
                            type="email"
                            id="emailInput"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                          />
                        </InputGroup>
                        {errors.email && (
                          <span className="text-danger">
                            {errors?.email?.message}
                          </span>
                        )}

                    {/* <input
                      type="email"
                      placeholder=" Enter Email Address"
                      className="form-control"
                      id="emailInput"
                      {...register("email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                    ></input>
                    {errors.email && (
                      <span className="text-danger">
                        {errors?.email?.message}
                      </span>
                    )} */}
                  </FormGroup>
                  <Row>
                    <Col md="6">
                      <FormGroup className="submit-form">
                        {/* <label htmlFor="viberInput">Viber</label> */}
                        <InputGroup>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Viber Number"
                          id="viberInput"
                          {...register("viber", {
                            required: "Viber is required",
                          })}
                        ></input>
                        </InputGroup>
                        {errors.viber && (
                          <span className="text-danger">
                            {errors?.viber?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        {/* <label>Password</label> */}
                        <InputGroup className="input-group-merge input-group-alternative">
                          <input
                            className="form-control pl-2"
                            placeholder="Enter Password"
                            type="password"
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                        </InputGroup>
                        {errors.password && (
                          <span className="text-danger">
                            {errors?.password?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="submit-form mt-4">
                    {/* <label htmlFor="addressInput">Address</label> */}
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Address"
                      id="addressInput"
                      {...register("address", {
                        required: "Address is required",
                      })}
                    ></textarea>
                    {errors.address && (
                      <span className="text-danger">
                        {errors?.address?.message}
                      </span>
                    )}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4 btn-warning"
                      color="default"
                      type="submit"
                      disabled={loading}
                      style={{ width: "100%" }}
                    >
                      Sign Up
                    </Button>
                  </div>
                  <div class="mt-2 d-flex justify-content-center">
                    <Link className="to-login" to="/login">
                      If you have account, please login.
                    </Link>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RegisterPage;
