import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Logo from "./../../assets/image/logo.png";
import "./Footer.css";

function Footer() {
  return (
    <>
  
      <Container fluid className="brand-footer p-3">
       
          <Row>
            <Col
              lg="4"
              md="4"
              sm="10"
              className="mx-auto mb-5 d-flex justify-content-center"
            >
              <div className="brand">
                <div className="logo-wrapper">
                  <img className="nt-logo" src={Logo} alt="nt-logo" />
                </div>
                <div className="brand-name-wrapper">
                  {/* <h2 className="brand-name">NT</h2> */}
                  <h4 className="brand-collection">Branded Collection</h4>
                </div>
              </div>
            </Col>
            <Col
              lg="4"
              md="4"
              sm="10"
              className="mx-auto d-flex justify-content-center mb-5"
            >
              <div className="footer-items">
                <div className="header-wrapper">
                  <h1 className="item-header">Help</h1>
                </div>
                <div className="footer-list-items">
                  <Link className="footer-list-item" to="/payment">
                    Payment
                  </Link>
                  {/* <Link className="footer-list-item" to="/">
                    FAQ
                  </Link> */}
                  <Link className="footer-list-item" to="/contact">
                    Contact Us{" "}
                  </Link>
                </div>
                <div className="footer-list-items">
                  {/* <Link className="footer-list-item" to="/">
                    Shipping
                  </Link> */}
                  {/* <Link className="footer-list-item" to="/">
                    Return
                  </Link>
                  <Link className="footer-list-item" to="/">
                    Store Locator
                  </Link> */}
                </div>
              </div>
            </Col>
            <Col
              lg="4"
              md="4"
              sm="10"
              className=" mx-auto d-flex justify-content-center mb-5"
            >
              <div className="footer-items">
                <div className="header-wrapper">
                  <h1 className="item-header">Explore</h1>
                </div>
                <div className="footer-list-items">
                  <Link className="footer-list-item" to="/categories">
                    Categories
                  </Link>

                  <Link className="footer-list-item" to="/products">
                    Products{" "}
                  </Link>
                </div>
                <div className="footer-list-items">
                  <Link className="footer-list-item" to="/">
                    Privacy Policy
                  </Link>
                  <Link className="footer-list-item" to="/">
                    Terms and Rights
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
      </Container>
    </>
  );
}

export default Footer;
