import React from "react";
import useQuery from "./../../hooks/useQuery";
import Category from "./../../components/Category/Category.js";
import FullScreenLoader from "../../components/FullScreenLoader";
function CategoryPage() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  let url = "categories?per_page=50";
  if (params.get("main_type")) url += `&main_type=${params.get("main_type")}`;

  const {
    response: { categorylists },
    loading,
    error,
  } = useQuery(url);

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <div>
      <Category categories={categorylists.data} />
    </div>
  );
}
export default CategoryPage;
