import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import NoContent from "../NoContent";
import "./Product.css";
function Product({ products }) {
  if (products.length === 0) return <NoContent body={"No Product to Display"} />;
  return (
    <>
      <Container className="product-section container-fluid mx-auto mb-4 mt-5">
        <h5 className="section-title text-center d-flex justify-content-center mb-5">
          Products
        </h5>
        <Row className="mx-auto">
          {products.map((element, index) => (
            <Col
              lg="4"
              md="6"
              sm="10"
              className="mx-auto mb-5"
              key={`product-item-${index}`}
            >
              <Link
                to={`/product/${element.id}`}
                className="text-decoration-none"
              >
                <Card className="product-item">
                  <div className="product-image-container ">
                    <img src={element.productimages[0].image} alt="product"/>
                  </div>
                  <div className="product-type">
                    <h4 className="item-name">{element.name} 
                    {/* ({element.size}) */}
                    </h4>
                    <p className="item-price">{element.price} Kyats</p>
                  </div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Product;
