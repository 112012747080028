import React from "react";
// import { Link } from "react-router-dom";
import useQuery from "./../../hooks/useQuery";
// import { Row, Col } from "reactstrap";
// import Category from "../../components/Category/Category";
import HeroSection from "../../components/Hero/HeroSection";
// import Product from "../../components/Products/Product";
import FullScreenLoader from "../../components/FullScreenLoader";

function HomePage({element}) {
  const {
    // response: { data },
    loading,
    error,
  } = useQuery("homepage");

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <div ref={element}>
      <HeroSection />
      {/* <Category categories={data.categories} />
      <Product products={data.products} />
      <Row className="text-center d-flex justify-content-center pb-5">
        <Col className="section-btn ">
          <Link to="/products">
            <button className="btn-see-all">See All</button>
          </Link>
        </Col>
      </Row> */}
    </div>
  );
}

export default HomePage;
