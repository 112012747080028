import React, { useState } from "react";
import { Link } from "react-router-dom";
import SubMenu from "./../Submenu/Submenu";
import { checkLogin } from "../../lib/auth";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "./../../assets/image/logo.png";
import "./Navbar.css";
import { Container } from "reactstrap";

function NavBar({ sticky }) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  

  return (
    <>
      <Container fluid className={sticky ? "navbar navbar-sticky" : "navbar"}>
        <div className="nav-container">
          <Link 
          // exact 
          to="/" className="nav-logo">
            <img
              src={Logo}
              className="logo "
              alt="logo"
              style={{ width: "100px", height: "100px" }}
            />
            Branded Collection
          </Link>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                // exact
                to="/"
                // activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                // exact
                to="/categories"
                // activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Categories
              </Link>
            </li>

            <li className="nav-item">
              <Link
                // exact
                to="/products"
                // activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link
                // exact
                to="/contact"
                // activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact
              </Link>
            </li>
            {!checkLogin() ? (
              <li className="nav-item">
                <Link
                  // exact
                  to="/login"
                  // activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Sign In
                </Link>
              </li>
            ) : (
              <>
                <li className="nav-item">
                  <Link
                    // exact
                    to="/account"
                    // activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Account
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    // exact
                    to="/cart"
                    // activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Cart
                  </Link>
                </li>
              </>
            )}
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}
          </div>
        </div>
      </Container>
      <SubMenu />
    </>
  );
}

export default NavBar;
