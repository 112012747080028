import { storeCache } from "./cache";
import { getData, sendData } from "../utli/apiService";
export async function addToCart(product) {
  let carts = await getCarts();

  if (!(await checkInCart(product))) {
    product.qty = 1;
    carts.push(product);
    await storeCarts(carts);
  } else {
    carts = [];
    product.qty = 1;
    carts.push(product);
    await storeCarts(carts);
  }
}

export async function getCarts() {
  const response = await getData("cart");
  if (!response.data.data) {
    storeCache("carts", "[]");
    return [];
  }
  const carts = response.data.data.value
    ? JSON.parse(response.data.data.value)
    : [];
  storeCache("carts", carts);
  return carts;
}

export async function removeFromCarts(product) {
  let carts = await getCarts();
  carts = carts.filter((element) => {
    return element.id !== product.id;
  });
  await storeCarts(carts);
}

export async function updateCarts(product) {
  let carts = await getCarts();
  carts = carts.map((element) => {
    if (element.id === product.id) {
      return product;
    } else {
      return element;
    }
  });
  await storeCarts(carts);
}

export async function storeCarts(carts) {
  await sendData("cart/save", { value: JSON.stringify(carts) });
  storeCache("carts", JSON.stringify(carts));
}

export async function checkInCart(product) {
  const carts = await getCarts();
  return carts ? carts.find((element) => element.id === product.id) : false;
}

export async function clearCache() {
  await sendData("cart/save", { value: "[]" });
  storeCache("carts", "[]");
}
