import React from "react";
// import { Link } from "react-router-dom";

import "./Submenu.css";
function Submenu() {  
  const url = "/categories?main_type";
  const filter = process.env.REACT_APP_DOMAIN+"categories?main_type=";

  return (
    <>
      <nav className="sub">
        <div className="subbar-container container">
          <ul className="subbar-menu">
            <li className="subbar-item horizontal-scrollable">
              {/* <p
                onClick={() => {
                  window.location = `/categories?main_type=Women`;
                }}
                className="sub-links"
              >
                Women
              </p> */}
              <a
                href={`${url}=Women`}
                className={ `${filter}Women` === window.location.href ? "sub-links active" : "sub-links"}
              >
                Women
              </a>
            </li>

            <li className="subbar-item horizontal-scrollable">
              {/* <p
                onClick={() => {
                  window.location = `/categories?main_type=Men`;
                }}
                className="sub-links"
              >
                Men
              </p> */}
              <a href={`${url}=Men`}
                className={`${filter}Men` === window.location.href ? "sub-links active" : "sub-links"}
             >
                Men
              </a>
            </li>
            <li className="subbar-item horizontal-scrollable">
              {/* <p
                onClick={() => {
                  window.location = `/categories?main_type=Kids`;
                }}
                className="sub-links"
              >
                Kids
              </p> */}
              <a href={`${url}=Kids`} 
                className={`${filter}Kids` === window.location.href ? "sub-links active" : "sub-links"}
             >
                Kids
              </a>
            </li>
            <li className="subbar-item horizontal-scrollable">
              {/* <p
                onClick={() => {
                  window.location = `/categories?main_type=Lifestyle`;
                }}
                className="sub-links"
              >
                Lifestyle
              </p> */}
              <a href={`${url}=Lifestyle`}    
                className={`${filter}Lifestyle` === window.location.href? "sub-links active" : "sub-links"}
             >
                Lifestyle
              </a>
            </li>
            <li className="subbar-item horizontal-scrollable">
              {/* <p
                onClick={() => {
                  window.location = `/categories?main_type=Beauty`;
                }}
                className="sub-links"
              >
                Beauty
              </p> */}
              <a href={`${url}=Beauty`} 
                className={`${filter}Beauty` === window.location.href? "sub-links active" : "sub-links"}
             >
                Beauty
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Submenu;
