import React from "react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { sendData } from "./../../utli/apiService";
import {
  Button,
  FormGroup,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
  CardBody,
  Card,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import "./report-to-admin.css";
function ReportToAdmin() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const submitData = async (values) => {
    const response = await sendData("report/store", { body: values.body });
    if (response) {
      NotificationManager.success("Submitted");
      reset();
    }
  };

  return (
    <>
      <div className="login">
        <Container>
          <Row>
            <Col lg="8" md="8" sm="4" className="mx-auto">
              <Card className="w-100 p-3">
                <CardBody>
                  <div className="title text-wrapper text-center mb-3">
                    <h4>Report To Admin</h4>
                  </div>

                  <Form onSubmit={handleSubmit(submitData)}>
                    <FormGroup className={classnames("mb-3")}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <textarea
                          className="form-control pl-2"
                          placeholder="Enter Your Content"
                          {...register("body", {
                            required: "Content is required",
                          })}
                          style={{ height: "200px", padding: "0 45px" }}
                        />
                      </InputGroup>
                      {errors.body && (
                        <span className="text-danger">
                          {errors?.body?.message}
                        </span>
                      )}
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        className="my-4 btn-warning"
                        color="default"
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ReportToAdmin;
