import React from "react";
import { Button, Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import useQuery from "./../../hooks/useQuery";
import FullScreenLoader from "../../components/FullScreenLoader";
import { getUserInfo } from "../../lib/auth";
import { getReadableDateDisplay } from "./../../utli/convertToHumanReadableTime";
import "./Order.css";
function Order() {
  const userID = getUserInfo().id;
  const {
    response: { orderlist },
    loading,
    error,
  } = useQuery(`orders/get?user_id=${userID}`);

  const getStatus = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "Pending";
      case 2:
        return "Confirm";
      case 3:
        return "Cancel";
      default:
        return null;
    }
  };

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <>
      <div className="order">
        <Container>
          <h4 style={{ fontWeight: "300" }}>Your Orders List</h4>
          <Table responsive hover dark bordered className="mx-auto">
            <thead>
              <tr>
                <th>No</th>
                <th>Date</th>
                <th>Item Count</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orderlist.data.map((element, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{getReadableDateDisplay(element.created_at)}</td>
                  <td>{element.orderdetails.length}</td>
                  <td>{getStatus(element.status)}</td>
                  <td>
                    <Link to={`/orders/${element.id}`}>
                      <Button size="sm">View</Button>
                    </Link>
                  </td>
                </tr>
              ))}
              <t></t>
            </tbody>
          </Table>
          {/* <Card className=" w-100">

        <CardBody className="m-5">
       
        </CardBody>
      </Card> */}
        </Container>
      </div>
    </>
  );
}

export default Order;
