import React from "react";
function NoContent({ body }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "60vh" }}
    >
      <h2>{body}</h2>
    </div>
  );
}

export default NoContent;
