import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { sendData } from "./../../utli/apiService";
import { storeCache } from "./../../lib/cache";

import {
  Button,
  FormGroup,
  Form,
  InputGroup,
  Container,
  Card,
  CardBody,
  Row,
  Col,
  // Row,
} from "reactstrap";

import "./Login.css";
function LoginPage() {
  // const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [{ loading }, setLoginStatus] = useState({
    loading: false,
    error: null,
  });

  const submitData = async (values) => {
    const response = await sendData("user/login", values);
    setLoginStatus({ loading: true, error: null });
    if (response) {
      storeCache("token", response.data.accessToken);
      storeCache("userInfo", JSON.stringify(response.data.userInfo));
      window.location = "/";
    }
    setLoginStatus({ loading: false, error: null });
  };

  return (
    <>
      <div className="login">
        <Container>
          <Row>
            <Col lg="5" md="8" sm="4" className="mx-auto">
              <Card className="w-100 ">
                <CardBody className="m-3">
                  <div className="title text-center mb-3">
                    <h4>Login</h4>
                  </div>

                  <Form onSubmit={handleSubmit(submitData)}>
                    <FormGroup className={classnames("mb-3")}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <input
                          type="number"
                          className="form-control pl-2"
                          placeholder="Enter Phone"
                          {...register("phone", {
                            required: "Phone is required",
                          })}
                        />
                      </InputGroup>
                      {errors.phone && (
                        <span className="text-danger">
                          {errors?.phone?.message}
                        </span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <input
                          className="form-control pl-2"
                          placeholder="Enter Password"
                          type="password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                      </InputGroup>
                      {errors.password && (
                        <span className="text-danger">
                          {errors?.password?.message}
                        </span>
                      )}
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4 btn-warning"
                        color="default"
                        type="submit"
                        disabled={loading}
                        style={{ width: "100%" }}
                      >
                        Sign in
                      </Button>
                    </div>
                    <div class="mt-2 d-flex justify-content-center">
                      <Link className="to-register" to="/register">
                        If you don't have account, please register.
                      </Link>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LoginPage;
