import React, { useState } from "react";
import {
  Badge,

  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import Parser from "html-react-parser";
import FullScreenLoader from "../../components/FullScreenLoader";
import useQuery from "./../../hooks/useQuery";
import { checkLogin } from "./../../lib/auth";
import { addToCart } from "../../lib/cart";
import "./Product.css";
import { Carousel } from "react-responsive-carousel";

function ProductDetail() {
  const [counter, setCounter] = useState(0);
  const [actionLoading, setActionLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const params = useParams();

  const {
    response: { productinfo },
    loading,
    error,
  } = useQuery(`products/detail/${params.id}`);

  const otherSizes = productinfo?.other_size
    ? JSON.parse(productinfo?.other_size)
    : [];

  const otherColours = productinfo?.other_colour
    ? JSON.parse(productinfo?.other_colour)
    : [];

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;

  const addToCartAction = async () => {
    if (checkLogin()) {
      setActionLoading(true);
      await addToCart(productinfo);
      setActionLoading(false);
      window.location = "/cart";
    } else {
      window.location = "/login";
    }
  };

  const handleModal = (index) => {
    setCounter(index);
    setModal(!modal);
  };
  return (
    <div className="product-detail-section">
      <Container>
        <Row>
          <Col lg="4" md="6" sm="10">
            <Carousel
              showArrows={false}
              infiniteLoop={true}
              swipeable={true}
              emulateTouch={true}
            >
              {productinfo.productimages.map((element, index) => (
                <div
                  className="product-image-container"
                  onClick={() => {
                    handleModal(index);
                  }}
                >
                  <img
                    key={`product-image-${index + 1}`}
                    style={{ padding: "0 2px" }}
                    src={element.image}
                    alt="product"
                  />
                  {/* <Button
                    className="legend rounded-0 btn-warning"
                    onClick={() => {
                      handleModal(index);
                    }}
                  >
                    View Image
                  </Button> */}
                </div>
              ))}
            </Carousel>

            <Modal centered isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}></ModalHeader>
              <ModalBody>
                <img src={productinfo.productimages[counter].image} alt="product"/>
              </ModalBody>
            </Modal>
          </Col>

          <Col
            lg="6"
            md="6"
            sm="6"
            className="product-information-wrapper mx-auto"
          >
            <Card
              className="product-information w-100"
              style={{ padding: "40px" }}
            >
              <CardBody>
                <div className="product-name-wrapper mb-3">
                  <label className="product-label">{productinfo.name} </label>
                  <span className="product-name">
                    {productinfo.category.name}
                  </span>
                </div>
                <div className="product-name-wrapper mb-3">
                  {Number(productinfo.stock) === 0 ? (
                    ""
                  ) : (
                    <div>
                      <label
                        className="product-label"
                        style={{
                          color: "#4bb543",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        In Stock
                      </label>
                      {/* <span
                    className="text-warning"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    {" "}
                    {productinfo.stock}
                  </span> */}
                    </div>
                  )}
                </div>

                <div className="product-info-wrapper mb-3">
                  <label className="product-info-label">
                    Product Information
                  </label>
                  <p className="product-info">
                    {Parser(productinfo.description)}
                  </p>
                </div>

                <div className="product-price-wrapper mb-3">
                  <label className="product-label">Price</label>
                  <span className="product-price"> {productinfo.price} Ks</span>
                </div>
                <div className="product-price-wrapper mb-3">
                  <div className="product-label">Colour</div>
                  <div
                    className="color"
                    style={{ backgroundColor: productinfo.colour }}
                    data-hex={productinfo.colour}
                  ></div>

                  {/* <span className="product-color"> {productinfo.colour}</span> */}
                </div>
                <div className="product-price-wrapper mb-3">
                  <div className="product-label">Size</div>
                  <Badge
                    color="dark"
                    className="mx-2 my-2"
                    style={{ fontSize: "16px" }}
                  >
                    {productinfo.size}
                  </Badge>

                  {/* <span className="product-sizes"> {productinfo.size} </span> */}
                </div>

                {otherColours.length > 0 && (
                  <div className="product-info-wrapper mb-3">
                    <div>
                      <label className="product-info-label">Other Colour</label>
                    </div>

                    <div className="colors">
                      {otherColours.map((element, index) => {
                        if (element.id !== parseInt(params.id)){
                          return (
                            <div
                              className="color mx-2 my-2 p-3 "
                              style={{ backgroundColor: element.name }}
                              data-hex={element.name}
                              onClick={() => {
                                window.location = element.link;
                              }}
                            ></div>
                            
                            
                            // <Badge
                            //   color="dark"
                            //   pill
                            //   onClick={() => {
                            //     window.location = `/product/${element.id}`;
                            //   }}
                            //   className="mx-2 my-2 p-3 product-price other-content"
                            //   style={{ fontSize: "16px" }}
                            //   key={`other-color-index-${index}`}
                            // >
                            //   {element.colour}
                            // </Badge>
                          );
                        }else{
                          return null;
                        }
                      })}
                    </div>
                    
                  </div>
                )}

                {otherSizes.length > 0 && (
                  <div className="product-info-wrapper mb-3">
                    <div>
                      <label className="product-info-label">Other Size</label>
                    </div>
                    <div>
                      {otherSizes.map((element, index) => {
                        if (element.id !== parseInt(params.id))
                        {
                          return (
                            <Badge
                              color="dark"
                              className=" mx-2 my-2 p-3 product-price other-content"
                              style={{ fontSize: "16px" }}
                              key={`other-color-index-${index}`}
                              onClick={() => {
                                window.location = `/product/${element.id}`;
                              }}
                            >
                              {element.size}
                            </Badge>
                          );
                        }else{
                          return null;
                        }
                      }
                      )}
                    </div>
                  </div>
                )}

                <div className="button-wrapper">
                  {Number(productinfo.stock) === 0 ? (
                    <button
                      onClick={addToCartAction}
                      className="btn-cart"
                      disabled={actionLoading}
                      textDecoration="line-through"
                    >
                      {!actionLoading ? "Pre Order" : "Loading.."}
                    </button>
                  ) : (
                    <button
                      onClick={addToCartAction}
                      className="btn-cart"
                      disabled={actionLoading}
                    >
                      {!actionLoading ? "Add To Cart" : "Loading.."}
                    </button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductDetail;
