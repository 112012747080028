import React from "react";
import "./HeroSection.css";
// import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// import { Carousel } from "react-responsive-carousel";
import useQuery from "./../../hooks/useQuery";
import BanenrImage from "./../../assets/image/banner.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "nuka-carousel";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

// import { useState } from "react/cjs/react.development";
import FullScreenLoader from "../FullScreenLoader";
function HeroSection() {
  const {
    response: { notilist },
    loading,
    error,
  } = useQuery("notifications");

  // const settings = {
  //   showArrows: false,
  //   interval: 3500,
  //   dynamicHeight: false,
  //   stopOnHover: false,
  //   infiniteLoop: true,
  //   showStatus: false,
  //   transitionTime: 500,
  //   showThumbs: false,
  //   showIndicators: true,
  //   swipeable: true,
  //   emulateTouch: true,
  // };

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <>
      <Container fluid className="hero">
        <Row>
          {/* <Col lg="6" className="d-flex flex-column justify-content-center  pt-4 pt-lg-0 order-2 order-lg-1">
            <div className="home__hero-text-wrapper">
            <h1 className="heading">NT</h1>
            <h2 className="home__hero-subtitle">Branded Collection</h2>
            <Link to="/products">
              <button className="btn-shop">Shop Now</button>
            </Link>
            </div>
          </Col> */}

          <Col lg="12">
            {/* <div className="home__hero-img-wrapper"> */}
            {notilist.data.length > 0 ? (
              <Carousel
              
                renderBottomLeftControls={({ previousSlide }) => (
                  <div onClick={previousSlide} className="arrowBack">
                    <MdOutlineArrowBackIos /> <span>PREVIOUS</span>
                  </div>
                )}
                renderBottomRightControls={({ nextSlide }) => (
                  <span onClick={nextSlide} className="arrowForward">
                    <span>NEXT</span> <MdOutlineArrowForwardIos />
                  </span>
                )}
                renderCenterLeftControls={false}   
                renderCenterRightControls={false}              
                // wrapAround={true}
                >
                {notilist.data.map((element, index) => (
                  <div key={index} className="hero-info">
                    <img src={element.image} className="hero-carousel" alt="noti" />
                    <div className="noti-info">
                      <h1>{element.title}</h1>
                      <p>{element.body}</p>
                    </div>
                  </div>
                ))}
              </Carousel>
            ) : (
              <div>
                <img src={BanenrImage} alt='bannerimg' />
              </div>
            )}
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HeroSection;
