import React from "react";
import { Button, Container, Table } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import useQuery from "./../../hooks/useQuery";
import FullScreenLoader from "../../components/FullScreenLoader";
import { getUserInfo } from "../../lib/auth";
import { getReadableDateDisplay } from "./../../utli/convertToHumanReadableTime";
import "./Order.css";
function OrderDetail() {
  const userInfo = getUserInfo();
  const params = useParams();
  const {
    response: { orderInfo },
    loading,
    error,
  } = useQuery(`orders/detail/${params.id}`);

  const getStatus = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "Pending";

      case 2:
        return "Confirm";

      case 3:
        return "Cancel";
    }
  };

  const getSubTotal = (products) => {
    let total = 0;
    products.forEach(
      (product) => (total += parseInt(product.price * product.qty))
    );
    return total;
  };

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <div className="order">
      <Container>
        <h4 style={{ fontWeight: "300" }}>Your Order Detail</h4>
        <Table responsive hover dark striped bordered>
          <tbody>
            <tr className="pb-3 pt-3">
              <td>Name</td>
              <td>{userInfo.name}</td>
            </tr>
            <tr className="pb-3 pt-3">
              <td>Status</td>
              <td>{getStatus(orderInfo.status)}</td>
            </tr>
            <tr className="pb-3 pt-3">
              <td>Date</td>
              <td>{getReadableDateDisplay(orderInfo.created_at)}</td>
            </tr>

            {getStatus(orderInfo.status) === "Pending" ? (
              <tr className="pb-3 pt-3">
                <td>Go to Payment</td>
                <td>
                  <Link to="/payment">
                    <Button size="sm">View</Button>
                  </Link>
                </td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </Table>

        <Table hover dark bordered>
          <thead>
            <tr>
              <th>No</th>
              <th>Item</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {orderInfo.order_details.map((element, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {element.product?.name} ({element.product?.colour}) (
                  {element.product?.size})
                </td>
                <td>{element.price}</td>
                <td>{element.qty}</td>
                <td style={{ textAlign: "right" }}>
                  {element.qty * element.price}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={4}>Sub Total : </td>
              <td style={{ textAlign: "right" }}>
                {getSubTotal(orderInfo.order_details)}
              </td>
            </tr>
          </tbody>
        </Table>
        {/* <Card className="p-5" style={{ width: "80%" }}>
        <CardTitle>
          <h4> Order Detail</h4>
         
        </CardTitle>
        <CardBody>

        </CardBody>
      </Card> */}
      </Container>
    </div>
  );
}

export default OrderDetail;
