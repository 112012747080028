import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { NotificationManager } from "react-notifications";
import {  useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "reactstrap";
import FullScreenLoader from "../../components/FullScreenLoader";
import useQuery from "../../hooks/useQuery";
// import { removeCache } from "../../lib/cache";
import { sendData } from "../../utli/apiService";
import { storeCache } from "./../../lib/cache";


function EditProfile() {
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [initState, setInitState] = useState({
    submitLoading: false,
  });
  
  const submitData = async (values) => {
    // console.log(props.match.params)
    values.id = params.id;
    setInitState({ ...initState, submitLoading: true });
    const response = await sendData("user/update", values);
    // console.log(response.data.user)
    if (response) {
      // NotificationManager.success("Update Success");
      storeCache("userInfo", JSON.stringify(response.data.user));
      // window.location.reload();
      window.location='/account'
      
    }
    setInitState({ ...initState, submitLoading: false });
    //window.location.reload()
  };
  const {
    response: { userInfo },
    loading,
    error,
  } = useQuery(`user/profile`);

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;

  return (
    <>
      <Container fluid className="register">
        <Row>
          <Col className="mx-auto">
            <Card>
              <CardBody>
                <div className="title text-center">
                  <h4>Update Info</h4>
                </div>

                <Form
                  className="submit-form"
                  onSubmit={handleSubmit(submitData)}
                >
                  <Row className="mb-2">
                    <Col md="6">
                      <FormGroup>
                        {/* <label>Name</label> */}
                        <InputGroup className="input-group-merge input-group-alternative">
                          <input
                            className="form-control pl-2"
                            placeholder=" Enter Username"
                            type="text"
                            {...register("name", {
                              required: "Name is required",
                            })}
                            defaultValue={userInfo.name}
                          />
                        </InputGroup>
                        {errors.name && (
                          <span className="text-danger">
                            {errors?.name?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        {/* <label>Phone</label> */}
                        <InputGroup className="input-group-merge input-group-alternative">
                          <input
                            className="form-control pl-2"
                            placeholder="Enter Phone"
                            type="number"
                            {...register("phone", {
                              required: "Phone is required",
                            })}
                            defaultValue={userInfo.phone}
                          />
                        </InputGroup>
                        {errors.phone && (
                          <span className="text-danger">
                            {errors?.phone?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="submit-form mb-4">
                    {/* <label htmlFor="emailInput">Email</label> */}
                    <input
                      type="email"
                      placeholder=" Enter Email Address"
                      className="form-control"
                      id="emailInput"
                      {...register("email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                      defaultValue={userInfo.email}
                    ></input>
                    {errors.email && (
                      <span className="text-danger">
                        {errors?.email?.message}
                      </span>
                    )}
                  </FormGroup>
                  {/* <Col md="6"> */}
                  <FormGroup className="submit-form">
                    {/* <label htmlFor="viberInput">Viber</label> */}
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Viber Number"
                      id="viberInput"
                      {...register("viber", {})}
                      defaultValue={userInfo.viber}
                    ></input>
                  </FormGroup>
                  {/* </Col> */}

                  <FormGroup className="submit-form mt-4">
                    {/* <label htmlFor="addressInput">Address</label> */}
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Address"
                      id="addressInput"
                      {...register("address", {
                        required: "Address is required",
                      })}
                      defaultValue={userInfo.address}
                    ></textarea>
                    {errors.address && (
                      <span className="text-danger">
                        {errors?.address?.message}
                      </span>
                    )}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4 btn-warning"
                      color="default"
                      type="submit"
                      // disabled={loading}
                      style={{ width: "100%" }}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EditProfile;
