import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row, Table } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import {
  getCarts,
  clearCache,
  updateCarts,
  removeFromCarts,
} from "../../lib/cart";
import FullScreenLoader from "../../components/FullScreenLoader";
import "./Cart.css";
import { getUserInfo } from "../../lib/auth";
import { sendData } from "./../../utli/apiService";

function Cart() {
  const [carts, setCarts] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  React.useEffect(() => {
    async function getData() {
      const carts = await getCarts();
      setCarts(carts);
    }
    getData();
  }, []);

  const incrementCounter = async (product) => {
    product.qty = product.qty + 1;
    await updateCarts(product);
    window.location.reload();
  };

  const inputCounter = async (product, qty) => {
    product.qty = Number(qty);
    await updateCarts(product);
    window.location.reload();
  };

  let decrementCounter = async (product) => {
    if (product.qty > 1) {
      product.qty = product.qty - 1;
      await updateCarts(product);
      window.location.reload();
    }
  };

  const onRemove = async (product) => {
    await removeFromCarts(product);
    window.location.reload();
  };

  function ButtonIncrement(props) {
    return (
      <Button
        // className="btn-increase"
        // style={{
        //   border: "none",
        //   padding: "5px 10px",
        //   background: "#2a3a65",
        //   color: "#fff",
        // }}
        className="bg-success"
        size="md"
        onClick={props.onClickFunc}
      >
        <i className="fas fa-plus"></i>
      </Button>
    );
  }

  const onOrder = () => {
    confirmAlert({
      title: "Confirm to order",
      message:
        "Are you sure to submit order.Admin will receive your order and continue next step. Thanks for shopping with us",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let data = {
              cart: carts.map((element) => ({
                product_id: element.id,
                qty: element.qty,
                price: element.price,
              })),
              user_id: getUserInfo().id,
            };
            setOrderLoading(true);
            const response = await sendData(`orders/store`, data);
            if (response) {
              await clearCache();
              setOrderLoading(false);
              window.location = "/orders";
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  function ButtonDecrement(props) {
    return (
      <Button
        // className="btn-decrease"
        // style={{
        //   border: "none",
        //   padding: "5px 10px",

        //   background: "#959595",
        //   color: "#fff",
        // }}
        className=""
        size="md"
        onClick={props.onClickFunc}
      >
        <i className="fas fa-minus"></i>
      </Button>
    );
  }

  function Display(props) {
    return (
      <input
        type="number"
        className="display"
        style={{
          background: "#f5f5f5",
          fontSize: "16px",
          fontWeight: "500",
          padding: "5px 0",
          width: "50px",
          textAlign: "center",
          color: "#0e0e0e",
          border: "none",
        }}
        defaultValue={props.element.qty}
        onChange={(event) => inputCounter(props.element, event.target.value)}
      />
      // {/* {props.message} */}
    );
  }

  if (orderLoading) return <FullScreenLoader />;
  return (
    <>
      <div className="cart-items">
        <Container>
          <h4 style={{ fontWeight: "300" }}>
            <i className="fas fa-shopping-cart"></i> Shopping Cart
          </h4>

          <Table responsive dark striped bordered className="mx-auto">
            <thead className="text-center">
              <tr>
                <th>Description</th>
                <th>Quantity</th>
                <th>Remove</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody className="text-center align-middle ">
              {carts.map((element, index) => (
                <tr key={index}>
                  <td>
                    <div className="product-desc-wrapper">
                      <img
                        src={element.productimages[0].image}
                        className="product-image"
                        alt="product"
                      />
                      <p>{element.name}</p>
                      <div className="product-colors">
                        <div
                          className="product-color"
                          style={{ backgroundColor: `${element.colour}` }}
                          // data-hex="#e1e851"
                          // className="mx-2 my-2 p-3 product-price"

                          // key={`other-color-index-${index}`}
                        ></div>
                      </div>

                      {/* <span>{element.colour}</span> */}
                      <br />
                      <span>{element.size}</span>
                    </div>
                  </td>
                  <td>
                    <Row>
                      <Col lg="4" md="4">
                        <ButtonIncrement
                          onClickFunc={() => incrementCounter(element)}
                        />
                      </Col>
                      <Col lg="4" md="4">
                        <Display element={element} />
                      </Col>
                      <Col lg="4" md="4">
                        <ButtonDecrement
                          onClickFunc={() => decrementCounter(element)}
                        />
                      </Col>
                    </Row>
                  </td>
                  <td>
                    <Button
                      className="btn-danger"
                      size="sm"
                      onClick={() => onRemove(element)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>

                  <td>{element.price * element.qty} Ks</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="text-center">
              <tr>
                <td>
                  <Link to="/">
                    <Button size="sm" className="">
                      <i className="fas fa-arrow-left"></i>
                    </Button>
                  </Link>
                </td>
                <td></td>
                <td></td>
                <td>
                  <Button
                    className="bg-success text-white"
                    onClick={onOrder}
                    disabled={carts.length === 0}
                    size="sm"
                  >
                    Order Now
                  </Button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Container>
      </div>
    </>
  );
}

export default Cart;
