import React from "react";
import Product from "./../../components/Products/Product.js";
import useQuery from "./../../hooks/useQuery";
import FullScreenLoader from "../../components/FullScreenLoader";
function ProductPage() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  let url = "products?per_page=50&groupBy=true&";
  if (params.get("category_id"))
    url += `&category_id=${params.get("category_id")}`;
  const {
    response: { productlist },
    loading,
    error,
  } = useQuery(url);

  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <div>
      <Product products={productlist.data} />
    </div>
  );
}

export default ProductPage;
