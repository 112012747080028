import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import FullScreenLoader from "../../components/FullScreenLoader";
import useQuery from "../../hooks/useQuery";
import "./Contact.css";

function Contact() {
  const {
    response: { info },
    loading,
    error,
  } = useQuery("contactinfos");
  if (loading) return <FullScreenLoader />;
  if (error) return <h3>{error.response.message}</h3>;
  return (
    <>
      <div className="contact">
        <Container>
        <Row className="my-5 py-5">
          <Col lg="8" className="mx-auto">
            {/* <div
              className="contact-wrapper p-5"
              style={{ background: "#f5f5f5" }}
            > */}
            <Card className="p-3">
              <CardBody>
              <div className="section-title text-center">
                <h2>Contact</h2>
              </div>
              <Row className="contact-info-wrapper ">
                <Col md="6" className="mt-3 d-flex align-items-center">
                  <div className="info-box">
                    <i class="fas fa-map-marker-alt icon"></i>
                    <div className="info-wrapper">
                      <h3>Address</h3>
                      <p className="subtitle"> {info.address}</p>
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mt-3 d-flex align-items-stretch">
                  <div className="info-box ">
                    <i class="fas fa-phone icon"></i>
                    <div className="info-wrapper">
                      <h3>Phone</h3>
                      <a className="contact-link" href={`tel:${info.phone}`}>
                        <a href={`tel:${info.phone}`} className="contact-info"> {info.phone}</a>
                      </a>
                    </div>
                  </div>
                </Col>

                <Col md="6" className="mt-3 d-flex align-items-stretch">
                  <div className="info-box ">
                    <i class="fas fa-share-alt icon"></i>
                    <div className="info-wrapper">
                      <h3>Our Social Media</h3>
                      <div className="social-links">
                        <a href={info.facebook} target="_blank" rel="noopener noreferrer" >
                          <i class="fab fa-facebook"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="6" className="mt-3 d-flex align-items-stretch">
                  <div className="info-box ">
                    <i class="fab fa-viber icon"></i>
                    <div className="info-wrapper">
                      <h3>Viber</h3>
                      <a
                        className="contact-link"
                        href={`viber://chat/?number=%2B+95${info.viber}`}
                        // href={`viber://chat?number=${info.viber}`}
                      >
                        <p className="contact-info"> {info.viber}</p>
                      </a>
                    </div>
                  </div>
                </Col>

                <Col md="6" className="mt-4 d-flex align-items-stretch">
                  <div className="info-box ">
                    <i class="fab fa-telegram icon"></i>
                    <div className="info-wrapper">
                      <h3>Telegram</h3>
                      <a className="contact-link" href={`https://telegram.me/${info.telegram}`}>
                        <p className="contact-info">{info.telegram}</p>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <div className="contact-info-wrapper">
            <div className="address-wrapper">
              <FaMapMarkerAlt className="fa-map-marker-alt"/>
              <i class="fas fa-map-marker-alt"></i>
              <p className="contact-info">{info.address}</p>
            </div>
            <div className="address-wrapper">
              <i class="fas fa-phone"></i>
              <a href={`tel:${info.phone}`}>
                <p className="contact-info"> {info.phone}</p>
              </a>
            </div>
            <div className="address-wrapper">
              <i class="fab fa-facebook-f"></i>

              <p className="contact-info">
                <a href={info.facebook} target="_blank">
                  Find Us On Facebook
                </a>
              </p>
            </div>
            <div className="address-wrapper">
              <i class="fab fa-viber"></i>
              <a href={`viber://chat/?number=${info.viber}`}>
                <p className="contact-info"> {info.viber}</p>
              </a>
            </div>
            <div className="address-wrapper">
              <i class="fab fa-telegram"></i>
              <p className="contact-info"> {info.telegram}</p>
            </div>
          </div> */}
              {/* </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
    </>
  );
}

export default Contact;
