import { getCache } from "./cache";

export function checkLogin() {
  if (getCache("token") && getCache("token").length > 0) {
    return true;
  }
  return false;
}

export function getUserInfo() {
  return JSON.parse(getCache("userInfo"));
}
