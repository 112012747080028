import React, { useState } from "react";
import "./ChangePassword.css";
import {
  Button,
  FormGroup,
  Form,
  InputGroup,
  Row,
  Container,
  Col,
  Card,
  CardBody,
} from "reactstrap";
// import classnames from "classnames";
import { useForm } from "react-hook-form";
import { sendData } from "../../utli/apiService";
import { removeCache } from "../../lib/cache";

function ChangePassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [{ loading }, setChangePwStatus] = useState({
    loading: false,
    error: null,
  });

  const logout = () => {
    removeCache("token");
    removeCache("userInfo");
    window.location = "/";
  };
  const submitData = async (values) => {
    const response = await sendData("user/changePassword", values);
    setChangePwStatus({ loading: true, error: null });

    if (response) {
      window.location.reload();
      logout();
    }
    setChangePwStatus({ loading: false, error: null });
  };
  return (
    <>
      <div className="change-password">
        <Container>
        <Row>
          <Col  lg="5" md="8" sm="6" className="mx-auto">
            <Card className="p-5">
              <CardBody>
                <div className="title text-wrapper text-center mb-3">
                  <h4>Change Password</h4>
                </div>

                <Form onSubmit={handleSubmit(submitData)}>
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <input
                        className="form-control pl-2"
                        placeholder="Enter New Password"
                        type="password"
                        {...register("password", {
                          required: "Password is required",
                        })}

                      />
                    </InputGroup>
                    {errors.password && (
                      <span className="text-danger">
                        {errors?.password?.message}
                      </span>
                    )}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4 btn-warning"
                      color="default"
                      type="submit"
                      disabled={loading}
                      style={{ width: "100%" }}
                    >
                      Change Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
    </>
  );
}

export default ChangePassword;
