import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./Category.css";

function Category({ categories }) {
  return (
    <>
      <Container className="category-section mx-auto">
        {/* <h5 className="section-title text-center d-flex justify-content-center mb-5">
          Category
        </h5> */}
        <Row>
          {categories.map((element, index) => (
            <Col
              lg="4"
              md="4"
              sm="6"
              className="mb-4"
              key={`category-list-${index}`}
            >
              <Link
                to={`/products?category_id=${element.id}`}
                style={{ textDecoration: "none" }}
              >
                <div
                  className="category-item mx-auto"
                  // style={{ background: "#f5f5f5" }}
                >
                  <Col lg="12" md="12" sm="6">
                    <img src={element.image} className="category-image d-flex justify-content-center" alt="categpry"/>
                  </Col>
                  <div className="category-type">
                    <h4>{element.name}</h4>
                    <p> Collection </p>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Category;
