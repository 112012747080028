import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import ProductPage from "./pages/Product";
import CategoryPage from "./pages/Category";
import Register from "./pages/Register";
import Account from "./pages/Account";
import ReportToAdmin from "./pages/ReportToAdmin";
import Contact from "./pages/Contact";
import Order from "./pages/Order";
import OrderDetail from "./pages/Order/Detail";
import Cart from "./pages/Cart";
// import Cart from "./pages/Cart/Cart";
import "./App.css";
import Footer from "./components/Footer/Footer";
import ProductDetail from "./pages/ProductDetail";
import { getCache } from "./lib/cache";
import "react-notifications/lib/notifications.css";
import useSticky from "./hooks/useSticky";
import ChangePassword from "./pages/ChangePassword";
import PaymentInfo from "./pages/PaymentInfo";
import EditProfile from "./pages/EditProfile";


function App() {
  const { isSticky, element } = useSticky();

  return (
    <BrowserRouter>
      <Navbar sticky={isSticky} />
      <Routes>
        <Route path="/" element={<HomePage element={element} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/categories" element={<CategoryPage />} />

        <Route
          path="/cart"
          element={
            <RequireAuth redirectTo="/login">
              <Cart />
            </RequireAuth>
          }
        ></Route>
                <Route
          path="/payment"
          element={
            <RequireAuth redirectTo="/login">
              <PaymentInfo />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/account"
          element={
            <RequireAuth redirectTo="/login">
              <Account />
            </RequireAuth>
          }
        /> <Route
        path="/update/:id"
        element={
          <RequireAuth redirectTo="/login">
            < EditProfile/>
          </RequireAuth>
        }
      />
        <Route
          path="/report-to-admin"
          element={
            <RequireAuth redirectTo="/login">
              <ReportToAdmin />
            </RequireAuth>
          }
        />
        <Route
          path="/change-password"
          element={
            <RequireAuth redirectTo="/login">
              <ChangePassword />
            </RequireAuth>
          }
        />
        <Route
          path="/orders"
          element={
            <RequireAuth redirectTo="/login">
              <Order />
            </RequireAuth>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <RequireAuth redirectTo="/login">
              <OrderDetail />
            </RequireAuth>
          }
        />
      </Routes>

      <Footer />
      <NotificationContainer />
    </BrowserRouter>
  );
}

function RequireAuth({ children, redirectTo }) {
  const token = getCache("token");
  return token ? children : <Navigate to={redirectTo} />;
}

export default App;
